.availability {
  padding: 0rem 4rem;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
}

.availability-search {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(128, 128, 128, 0.38);
  border-radius: 3px;
  background-color: white;
  padding: 5px;
  position: absolute;
  bottom: -33px;
  width: 90%;
}

.search-item {
  display: flex;
  align-items: center;
}

.search-button {
  padding: 20px 30px;
  background-color: #b48d52;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
}
.search-button:hover {
  background-color: #063b6d;
}

.search-item svg {
  color: grey;
  font-size: 25px;
  margin: 10px;
}

.search-input {
  border: none;
  background: none;
  font-size: 16px;
  color: grey;
  outline: 0px;
}

.search-text {
  color: grey;
  cursor: pointer;
}

.date {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 60px;
  z-index: 2;
}

.date-btn {
  border: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  background-color: #b48d52;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.date-btn:hover {
  background-color: #063b6d;
}

.options {
  z-index: 2;
  position: absolute;
  top: 60px;
  background-color: white;
  color: gray;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.4);
}

.optionItem {
  width: 200px;
  display: flex;
  justify-content: space-between;
  margin: 10px;
}

.optionCounter {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: black;
}

.optionCounterButton {
  width: 30px;
  height: 30px;
  border: 1px solid #b48d52;
  color: #b48d52;
  cursor: pointer;
  background-color: white;
}

.optionCounterButton:disabled {
  cursor: not-allowed;
}

@media screen and (max-width: 1050px) {
  .availability-search {
    padding: 3px;
    position: absolute;
    bottom: -30px;
    width: 90%;
  }

  .search-item {
    display: flex;
    align-items: center;
  }

  .search-button {
    padding: 15px 20px;
    font-size: 12px;
  }

  .search-item svg {
    font-size: 20px;
  }

  .search-input {
    font-size: 14px;
  }

  .search-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 700px) {
  .availability {
    padding: 0rem 1rem;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
  }

  .availability-search {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    position: relative;
    width: 100%;
  }

  .search-item {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .search-button {
    padding: 15px 30px;
    margin-top: 20px;
    width: 100%;
  }

  .date {
    top: 90px;
  }

  .options {
    top: 130px;
  }
}
