.nearby {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6rem 4rem;
}

.nearby-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
  margin-top: 30px;
}

.nearby-place {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 270px;
  width: 270px;
  border: 5px solid #b48d52;
  border-radius: 50px;
}

.nearby-place svg {
  font-size: 70px;
  margin: 10px;
  color: #b48d52;
}

.nearby-place h3 {
  font-size: 20px;
  margin: 10px;
  font-weight: bold;
}

.nearby-place p {
  font-size: 14px;
  color: grey;
}

@media screen and (max-width: 1050px) {
  .nearby {
    padding: 3rem 1rem;
  }

  .nearby-container {
    margin-top: 20px;
  }

  .nearby-place {
    height: 170px;
    width: 170px;
    border: 5px solid #b48d52;
    border-radius: 40px;
  }

  .nearby-place svg {
    font-size: 50px;
    margin: 10px;
    color: #b48d52;
  }

  .nearby-place h3 {
    font-size: 16px;
    margin: 10px;
    font-weight: bold;
  }

  .nearby-place p {
    text-align: center;
    font-size: 14px;
    color: grey;
  }
}

@media screen and (max-width: 700px) {
  .nearby {
    padding: 2rem 1rem;
  }

  .nearby-container {
    flex-direction: column;
    align-items: center;
    margin-top: 15px;
  }

  .nearby-place {
    margin: 10px;
  }
}
