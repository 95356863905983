.banner {
  background: url("../../Media/NOIR66.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  background-blend-mode: overlay;
  background-color: rgba(0, 0, 0, 0.392);
  padding: 7rem 16rem;
}

.banner h1 {
  font-size: 35px;
  font-weight: bold;
  color: white;
  line-height: 1;
}

@media screen and (max-width: 1050px) {
  .banner {
    padding: 5rem;
  }
}

@media screen and (max-width: 700px) {
  .banner {
    padding: 4rem 2rem;
  }

  .banner h1 {
    font-size: 30px;
  }
}
