@import url("https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap");

.navbar {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: rgb(231, 231, 231);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 4rem;
  z-index: 1000;
  font-family: "Poppins", sans-serif;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.079);
}

.logo {
  height: 70px;
}

.navbar-right {
  display: flex;
  justify-content: space-between;
}

.navbar-links a {
  text-decoration: none;
  /* color: #B48D52; */
  color: #063b6d;
  font-weight: 600;
  padding: 15px;
  transition: 0.3s ease;
}
.navbar-links a:hover {
  /* color: #063B6D; */
  color: #b48d52;
}

.nav-button {
  padding: 10px 20px;
  background-color: #b48d52;
  margin-left: 20px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.2;
  border-radius: 2px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
}
.nav-button:hover {
  background-color: #063b6d;
}

.navbar-menu-container a {
  text-decoration: none;
  color: #b48d52;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;

  margin: 0 1rem;
  cursor: pointer;
}

.navbar-menu {
  margin-left: 1rem;

  display: none;
  position: relative;
}

.navbar-menu svg {
  cursor: pointer;
}

.navbar-menu-container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: end;
  background: #063b6d;
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 180px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.362);
}

.navbar-menu-container a {
  margin: 10px 0;
}

@media screen and (max-width: 1050px) {
  .navbar-links {
    display: none;
  }

  .navbar-menu {
    display: flex;
  }
  .nav-button {
    margin-left: 0px;
  }
}

@media screen and (max-width: 700px) {
  .navbar {
    padding: 10px;
  }

  .navbar-menu-container {
    top: 20px;
  }

  .nav-button {
    margin-left: 0px;
  }
}
