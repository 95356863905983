.footer{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 6rem 60px;
    color: rgb(95, 95, 95);
    background: #063B6D;
}

.footer h3, h5, p{
    color: white;
}

/* .footer-left p{
    margin: 10px 0px;
    cursor: pointer;
    transition: 0.3s ease;
}
.footer-right p:hover{
    color: grey;
} */
.footer-left{
    width: 33.3%;
}

.contact-link{
    margin: 10px 0px;
}

.contact-link a{
    cursor: pointer;
    color: white;
    text-decoration: none;
    transition: 0.3s ease;
}

.contact-link a:hover{
    color: grey;
}

.contact-link svg{
    margin-right: 10px;
}

.contact-link-social svg{
     color: white;
     margin-right: 15px;
     margin-top: 10px;
     cursor: pointer;
}
.contact-link-social svg:hover{
    color: grey;
}

.footer-center{
    width: 33.3%;
    text-align: center;
}

.footer-center p{
    font-size: 16px;
    margin: 30px 0px 10px 0px;
}

.footer-right{
    width: 33.3%;
    text-align: right;
}

.footer-right a{
    text-decoration: none;
}

.footer-right p{
    margin: 10px 0px;
    cursor: pointer;
    transition: 0.3s ease;
}

.footer-right p:hover{
    color: grey;
}

@media screen and (max-width: 1150px){
    .footer{
        flex-direction: column;
        text-align: center;
    }

    .footer-center{
        margin: 40px 0px;
    }
    
    .footer-center p{
        font-size: 20px;
        margin-bottom: 10px;
    }
    
    .footer-right{
        text-align: center;
    }
}

@media screen and (max-width: 650px){
    .footer{
        flex-direction: column;
        text-align: center;
    }

    .footer-left{
        width: 100%;
    }

    .footer-center{
        width: 100%;
        margin: 40px 0px;
    }
    
    .footer-center p{
        font-size: 15px;
        margin-bottom: 10px;
    }
    
    .footer-right{
        width: 100%;
        text-align: center;
    }
}