.whyus{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 4rem;
}

.whyus-boxes{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.whyus-box{
    width: 23%;
    border: 1px solid rgba(134, 134, 134, 0.497);
    border-radius: 3px;
    padding: 40px 20px;
    text-align: center;
    transition: .3s ease-in-out;
}
.whyus-box:hover{
    background-color: rgb(255, 255, 255);
    border: none;
}

.whyus-box svg{
    font-size: 80px;
    color: #B48D52;
}

.whyus-box h3{
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0px;
    color: #063B6D;
}

.whyus-box p{
    font-size: 14px;
    color: grey;
}

@media screen and (max-width: 1050px){
    .whyus{
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 700px){
    .whyus{
        padding: 3rem 1rem;
    }
    
    .whyus-boxes{
        flex-direction: column;
        margin-top: 10px;
        align-items: center;
    }
    
    .whyus-box{
        width: 80%;
        margin: 10px;
        padding: 40px 20px;
    }
}