.awards{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 6rem;
}

.award-containers{
    display: flex;
    margin: 30px;
}

.award-container{
    width: 50%;
    display: flex;
    align-items: center;
}

.award-container img{
    width: 150px;
    height: 150px;
    margin-right: 15px;
    border: 10px solid #B48D52;
    border-radius: 2px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.492);
}

.award-container p{
    font-size: 16px;
    font-style: italic;
    color: grey;
}

@media screen and (max-width: 1050px){
    .awards{
        padding: 2rem;
    }
    
    .award-containers{
        margin: 15px;
    }
    
    .award-container{
        margin: 5px;
    }
    
    .award-container img{
        width: 120px;
        height: 120px;
        border: 8px solid #B48D52;
    }
    
    .award-container p{
        font-size: 14px;
    }
}

@media screen and (max-width: 700px){
    .awards{
        padding: 1.5rem;
    }
    
    .award-containers{
        flex-direction: column;
        margin: 10px;
    }
    
    .award-container{
        width: 100%;
        margin: 10px;
    }
    
    .award-container img{
        width: 100px;
        height: 100px;
        margin-right: 15px;
        border: 7px solid #B48D52;
    }
    
    .award-container p{
        font-size: 12px;
    }
}