.blogpage{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 4rem;
}
.blog-boxes{
    display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  margin-top: 30px;
}

.blog-box{
    width: 28vw;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.236);
    border-radius: 3px;
    transition: 0.3s ease-in-out;
    background: white;
}.blog-box:hover{
    padding: 5px;
    box-shadow: none;
}

.blog-box img{
    width: 100%;
    height: 250px;
}

.blogbox-content{
    padding: 20px;
}
.blogbox-content a{
    color: #063B6D;
    text-decoration: none;
}
.blogbox-content p{
    font-size: 15px;
    color: black;
}

.blog-date{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.blog-date p{
    color: grey;
    font-size: 12px;
}


@media screen and (max-width: 1050px){
    .blogpage{
        padding: 4rem;
    }

    .blog-box img{
        height: 200px;
    }
    
    .blogbox-content{
        padding: 10px;
    }
    
    .blog-date{
        margin-top: 10px;
    }
}

@media screen and (max-width: 700px){
    .blogpage{
        padding: 3rem 1rem;
    }
    
    .blog-boxes{
        display: flex;
        flex-direction: column;
      margin-top: 10px;
    }
    
    .blog-box{
        width: 100%;
    }
}