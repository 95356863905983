.facilities{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 4rem;
}

.facilities-container{
    display: flex;
    width: 80%;
    justify-content: space-around;
    margin-top: 30px;
}

.facility{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.facility h3{
    font-size: 20px;
    margin-top: 20px;
    color: rgb(80, 80, 80);
}

.circle{
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 15px rgba(128, 128, 128, 0.336);
    height: 200px;
    width: 200px;
    border-radius: 50%;
    transition: 0.3s ease-in;
}.circle:hover{
    background-color: white;
}

.circle svg{
    font-size: 100px;
    color: rgb(99, 99, 99);
    transition: 0.3s ease-in;
}.circle svg:hover{
    color: #B48D52;
}

@media screen and (max-width: 1150px){
    .facilities{
        padding: 3rem 2rem;
    }
    
    .facilities-container{
        width: 90%;
        margin-top: 20px;
    }
    
    .circle{
        height: 150px;
        width: 150px;
    }
    
    .circle svg{
        font-size: 60px;
    }
}

@media screen and (max-width: 650px){
    .facilities{
        padding: 2rem;
    }
    
    .facilities-container{
        flex-direction: column;
        width: 100%;
        margin-top: 10px;
    }
    
    .facility{
        margin-top: 20px;
    }
    .facility h3{
        font-size: 20px;
        margin: 20px;
    }
    
    .circle{
        height: 180px;
        width: 180px;
    }
    
    .circle svg{
        font-size: 80px;
    }
}