.header-banner{
    background: url('../../Media/NOIR65.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    background-blend-mode: overlay;
    background-color: rgba(0, 0, 0, 0.392);
    padding: 25vh 0rem 8vh 0rem;
    text-align: center;
}

.header-banner h1{
    font-size: 40px;
    font-weight: bold;
    color: white;
}

@media screen and (max-width: 700px){
    .header-banner h1{
        font-size: 28px;
    }
}