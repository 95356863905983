.about{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6rem 4rem;
}

.about-left{
    width: 50%;
    padding-right: 40px;
}

.about-left p{
    font-size: 15px;
    color: rgb(153, 153, 153);
}

.about button{
    padding: 15px 40px;
    margin-top: 30px;
    background-color: #B48D52;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.2;
    border-radius: 3px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in;
}
.about button:hover{
    background-color: #063B6D;
}

.about-right{
    width: 50%;
    display: flex;
    justify-content: flex-end;
    padding-left: 50px;
}

.about-right img{
    height: 500px;
    width: 50%;
    object-fit: cover;
    margin-left: 15px;
    border-radius: 3px;
}


@media screen and (max-width: 1050px){
    .about{
        padding: 3rem 2rem;
    }

    .about-right{
        padding-left: 0px;
    }
    
    .about-right img{
        width: 50%;
        margin-left: 10px;
    }
}

@media screen and (max-width: 700px){
    .about{
        flex-direction: column;
        padding: 3rem 1rem;
    }
    
    .about-left{
        width: 100%;
        margin-bottom: 30px;
    }
    
    .about-right{
        width: 100%;
        flex-direction: column;
        justify-content: center;
        padding-left: 0px;
    }
    
    .about-right img{
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }
}