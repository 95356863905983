.apartments{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 4rem;
}

.apartments-boxes{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.apartments-box{
    width: 32%;
    border-radius: 3px;
    box-shadow: 0px 2px 15px rgba(171, 171, 171, 0.704);
    background: white;
}

.apartments-box img{
    width: 100%;
    height: 250px;
}

.box-content{
    padding: 20px;
}

.box-content h3{
    font-size: 20px;
    font-weight: bold;
    color: #063B6D;
}

.box-content p{
    color: grey;
    padding: 10px 0px;
    font-size: 15px;
}

.box-content button{
    padding: 10px 40px;
    background-color: #B48D52;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.2;
    border-radius: 2px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in;
}
.box-content button:hover{
    background-color: #063B6D;
}

@media screen and (max-width: 1050px){
    .apartments{
        padding: 3rem 2rem;
    }
}

@media screen and (max-width: 700px){
    .apartments{
        padding: 3rem 1rem;
    }
    
    .apartments-boxes{
        flex-direction: column;
        margin-top: 10px;
    }
    
    .apartments-box{
        width: 100%;
        margin-bottom: 20px;
    }
}