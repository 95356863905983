.ig-gallery {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4rem 0 4rem 4rem;
}

.ig-gallery-content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  min-width: 500px;
  padding-right: 2rem;
}

.ig-gallery-content button {
  padding: 15px 40px;
  margin-top: 30px;
  background-color: #b48d52;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.2;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
}
.ig-gallery-content button:hover {
  background-color: #063b6d;
}

.ig-gallery-images {
  flex: 1;
  display: flex;
  flex-direction: row;
  max-width: 60%;
  position: relative;
}

.ig-gallery-images_container {
  display: flex;
  flex-direction: row;
  width: max-content;
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.ig-gallery-images_container::-webkit-scrollbar {
  display: none;
}

.ig-gallery-images_card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 301px;
  height: 447px;
  margin-right: 2rem;
}

.gallery__image-icon {
  position: absolute;
  color: #fff;
  font-size: 2rem;
  opacity: 0.5;
  transition: 0.5s ease;
  cursor: pointer;
}

.ig-gallery-images_card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  transition: 0.5s ease;
}

.ig-gallery-images_card:hover img {
  opacity: 0.35;
}

.ig-gallery-images_card:hover .gallery__image-icon {
  opacity: 1;
}

.ig-gallery-images_arrows {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 1rem;
  position: absolute;
  bottom: 5%;
}

.gallery__arrow-icon {
  color: #b48d52;
  font-size: 2rem;
  cursor: pointer;
  background-color: #063b6d;
  border-radius: 5px;
}

.gallery__arrow-icon:hover {
  color: white;
}

@media screen and (min-width: 2000px) {
  .ig-gallery-content {
    min-width: 1000px;
    padding-right: 4rem;
  }

  .ig-gallery-images_card {
    min-width: 400px;
    height: 550px;
  }
}

@media screen and (max-width: 1150px) {
  .ig-gallery {
    flex-direction: column;
  }

  .ig-gallery-images {
    max-width: 100%;
    margin: 5rem 0;
  }
}

@media screen and (max-width: 850px) {
  .ig-gallery {
    padding: 3rem 0 3rem 2rem;
  }
}

@media screen and (max-width: 650px) {
  .ig-gallery {
    padding: 3rem 0 3rem 1rem;
  }

  .ig-gallery-content {
    min-width: 100%;
  }

  .ig-gallery-images_card {
    min-width: 240px;
    height: 320px;
  }
}
