@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

.slider {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  white-space: nowrap;
}
.slider .slider_wrapper {
  position: relative;
  height: 100%;
  width: 100%;
}
.slider_wrapper .slide {
  display: inline-block;
  height: 100%;
  width: 100%;
  position: relative;
}

.slide .slide_content {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 888;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.slide .slide_content h1 {
  color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: 700;
}
.slide .slide_content p {
  font-size: 20px;
  margin-top: 10px;
}
.slide .slide_content button {
  padding: 20px 40px;
  margin-top: 30px;
  background-color: #b48d52;
  color: #ffffff;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.2;
  border-radius: 3px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
}
.slide .slide_content button:hover {
  background-color: #063b6d;
}
.slider .dots_wrapper {
  z-index: 999;
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}
.dots_wrapper .dot {
  color: white;
  font-size: 40px;
  font-weight: 700;
  cursor: pointer;
}
.dots_wrapper .dot.active {
  color: #b48d52;
}

@media screen and (max-width: 700px) {
  .slide .slide_content h1 {
    font-size: 22px;
  }
  .slide .slide_content p {
    font-size: 13px;
  }
  .slider .dots_wrapper {
    bottom: 30px;
    gap: 8px;
  }
  .dots_wrapper .dot {
    color: grey;
    font-size: 30px;
  }
}

@media screen and (max-width: 300px) {
  .slide .slide_content h1 {
    font-size: 18px;
  }
  .slide .slide_content p {
    font-size: 10px;
  }
  .slide .slide_content button {
    padding: 10px 20px;
    margin-top: 15px;
    font-size: 12px;
    border-radius: 2px;
  }
}
