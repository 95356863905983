.testimonials{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 100px;
}

.testimonials h1{
    margin-bottom: 50px;
}

.testimonial-profile{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.testimonial-profile p {
    font-size: 20px;
    font-weight: bold;
    color: #B48D52;
    margin: 0px 20px;
}

.quote{
    font-size: 16px;
    color: grey;
    margin: 5px 200px;
}

.testimonials img{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    border: 2px solid #B48D52;
    object-fit: cover;
}

.toggles {
    display: flex;
}

.toggles span{
    height: 20px;
    width: 20px;
    margin: 15px 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.toggles span::before{
    content: "";
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: silver;
    transition: background-color 0.3s ease;
}

.active-toggle{
    background-color: #B48D52;
    border-radius: 50%;
}

.toggles span:hover::before{
    background-color: #063B6D;
}

@media screen and (max-width: 1150px){
    .testimonials{
        padding: 40px;
    }

    .testimonials h1{
        margin-bottom: 30px;
    }
    
    .testimonial-profile{
        margin-bottom: 0px;
    }
    
    .testimonial-profile p {
        font-size: 20px;
        margin: 0px 10px;
    }
    
    .quote{
        margin: 5px 20px;
    }
}

@media screen and (max-width: 700px){
    .testimonials{
        padding: 20px;
    }

    .testimonials h1{
        margin-bottom: 20px;
    }
    
    .testimonial-profile{
        margin-bottom: 0px;
    }
    
    .testimonial-profile p {
        font-size: 18px;
        margin: 0px 10px;
    }
    
    .quote{
        margin: 5px 20px;
    }
  
    .testimonials img{
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid #B48D52;
    }

    .toggles span{
        height: 10px;
        width: 10px;
        margin: 10px 3px;
    }

    .toggles span::before{
        height: 5px;
        width: 5px;
    }
}