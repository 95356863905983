@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&family=Pacifico&family=Poppins&display=swap');

body{
  font-family: 'Poppins', sans-serif;
  background: rgb(231, 231, 231);
  scroll-behavior: smooth;
}

.small-header{
  font-size: 15px;
    font-weight: bolder;
    letter-spacing: 2px;
    color: #B48D52;
}

.header{
  font-size: 40px;
    font-weight: bold;
    color: #063B6D;
    margin-bottom: 20px;
}

 .scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
}

@keyframes scale-up-center {
0% {
  -webkit-transform: scale(0.5);
          transform: scale(0.5);
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
}
}


@media screen and (max-width: 700px){
  .small-header{
    letter-spacing: 1px;
  }
  .header{
    font-size: 27px;
  }
}