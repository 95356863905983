.gallerypage {
  text-align: center;
  padding: 6rem 4rem;
}

.img-grid {
  margin: 40px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}
.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  border-radius: 3px;
  position: absolute;
  top: 0;
  left: 0;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 8vh;
  background: rgba(0, 0, 0, 0.534);
}
.backdrop img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.547);
  border: 3px solid #b48d52;
}

@media screen and (max-width: 1050px) {
  .gallerypage {
    padding: 3rem;
  }

  .backdrop img {
    max-width: 80%;
  }
}

@media screen and (max-width: 700px) {
  .gallerypage {
    padding: 3rem 1rem;
  }

  .img-grid {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
  }

  .backdrop {
    display: flex;
    align-items: center;
  }
  .backdrop img {
    max-width: 95%;
  }
}
