.leaders{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 4rem;
}

.leaders-boxes{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
}

.leaders-box{
    margin: 15px;
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.153);
    background: white;
    width: 30%;
}

.leaders-box img{
    object-fit: cover;
    width: 100%;
    height: 400px;
}

.leaderbox-content{
    padding: 20px;
}

.leaderbox-content h3{
    color: #063B6D;
    font-size: 25px;
    font-weight: bold;
}
.leaderbox-content h6{
    color: grey;
    font-size: 16px;
    margin-top: 10px;
}


@media screen and (max-width: 1050px){
    .leaders{
        padding: 3rem 2rem;
    }
    
    .leaders-boxes{
        margin-top: 10px;
    }
    
    .leaders-box{
        margin: 10px;
    }
    
    .leaders-box img{
        width: 100%;
        height: 250px;
    }
    
    .leaderbox-content h3{
        font-size: 20px;
    }
    .leaderbox-content h6{
        font-size: 14px;
        margin-top: 5px;
    }
}

@media screen and (max-width: 700px){
    .leaders{
        padding: 3rem 1rem;
    }
    
    .leaders-boxes{
        flex-direction: column;
        align-items: center;
        margin-top: 0px;
    }
    
    .leaders-box{
        margin: 15px;
        width: 80%;
    }
    
    .leaders-box img{
        width: 100%;
        height: 350px;
    }
}