.video {
  height: 90vh;
  position: relative;
}

.video video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  inset: 0;
}

.video-overlay_circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 1px solid #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 1050px) {
  .video {
    height: 60vh;
  }

  .video-overlay_circle {
    width: 70px;
    height: 70px;
  }
}

@media screen and (max-width: 700px) {
  .video {
    height: 40vh;
  }

  .video-overlay_circle {
    width: 50px;
    height: 50px;
  }
}
