.contactpage{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 4rem;
}

.contact-boxes{
    display: flex;
    justify-content: space-between;
    width: 70vw;
}

.contact-box{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.164);
    border-radius: 3px;
    width: 31%;
    height: 280px;
    background: white;
}.contact-box svg{
    font-size: 40px;
    color: #B48D52;
}
.contact-box h3{
    margin: 20px 0px 10px 0px;
}.contact-box a{
    text-decoration: none;
}
.contact-box h6{
    font-size: 15px;
    font-weight: 100;
    color: grey;
}

.contact-form{
    display: flex;
    margin-top: 5rem;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.164);
    width: 100%;
    background: white;
}

.form-img{
    width: 50%;
}

.form{
    width: 50%;
    padding: 4rem;
}

.form input{
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    border: 2px rgba(192, 192, 192, 0.369) solid;
    border-radius: 2px;
    outline-color: #B48D52;
}

.form textarea{
    width: 100%;
    margin-bottom: 20px;
    padding: 20px;
    border: 2px rgba(192, 192, 192, 0.369) solid;
    border-radius: 2px;
    outline-color: #B48D52;
    font-family: 'Poppins', sans-serif;
}

.form button{
    padding: 20px 40px;
    background-color: #B48D52;
    color: #ffffff;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 1.2;
    border-radius: 2px;
    cursor: pointer;
    border: none;
    transition: all 0.3s ease-in;
}.form button:hover{
    background-color: #063B6D;
}

@media screen and (max-width: 1050px){
    .contactpage{
        padding: 3rem;
    }
    
    .contact-boxes{
        width: 80vw;
    }
    .form{
        padding: 2rem;
    }
}

@media screen and (max-width: 700px){
    .contactpage{
        padding: 3rem 1rem;
    }
    
    .contact-boxes{
        width: 95vw;
    }
    
    .contact-box{
        height: 150px;
    }.contact-box svg{
        font-size: 20px;
    }
    .contact-box h3{
        font-size: 12px;
        margin: 10px 0px 5px 0px;
    }
    .contact-box h6{
        font-size: 10px;
    }
    
    .contact-form{
        flex-direction: column;
        margin-top: 3rem;
    }
    
    .form-img{
        width: 100%;
        height: 50vh;
    }
    
    .form{
        width: 100%;
        padding: 1.5rem;
    }
}

@media screen and (max-width: 600px){
    .contact-box{
        height: 120px;
    }.contact-box svg{
        font-size: 20px;
    }
    .contact-box h3{
        font-size: 10px;
        margin: 8px 0px 0px 0px;
    }
    .contact-box h6{
        font-size: 8px;
    }
}