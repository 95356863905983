.blog-section{
  margin: 8rem 4rem;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.236);
  border-radius: 10px;
  background: white;
}.blog-section img{
  width: 100%;
  height: 40vh;
  object-fit: cover;
}

.blog-info{
  padding: 40px 60px;
}.blog-info h3{
  font-size: 30px;
  color: #063B6D;
}

.blogsection-date{
  margin-bottom: 20px;
}.blogsection-date h6{
  color: grey;
  font-size: 14px;
}

.blog-info p{
  color: black;
  margin-bottom: 15zpx;
}

@media screen and (max-width: 700px){
  .blog-section{
    margin: 8rem 1rem;
  }.blog-section img{
    width: 100%;
    height: 20vh;
    object-fit: cover;
  }
  
  .blog-info{
    padding: 20px 10px;
  }.blog-info h3{
    font-size: 20px;
  }
  .blog-info p{
    font-size: 14px;
  }
  
  .blogsection-date{
    margin-bottom: 20px;
  }.blogsection-date h6{
    font-size: 10px;
  }
}