.booking {
  position: relative;
  padding: 4rem;
}

.booking-form {
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.164);
  padding: 4rem;
  background: white;
}

label {
  font-size: 18px;
}

.input-text {
  margin: 20px 0px 20px 0px;
  width: 100%;
  font-size: 18px;
  margin-bottom: 20px;
  padding: 20px;
  border: 2px #c0c0c05e solid;
  border-radius: 2px;
  outline-color: #b48d52;
}

.input-time {
  font-size: 18px;
  border: none;
}

.radio {
  margin-top: 20px;
}

form h3 {
  border-top: 0.5px solid #b48d52;
  padding-top: 20px;
  margin: 20px 0px 10px 0px;
  font-size: 20px;
}

form p {
  color: black;
}

ul {
  margin: 10px;
}
li {
  margin: 10px;
}

.book-btn {
  padding: 20px 40px;
  margin-top: 40px;
  background-color: #b48d52;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 1.2;
  border-radius: 2px;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease-in;
}
.bookbtn:hover {
  background-color: #063b6d;
}

.date-input {
  border: 2px #c0c0c05e solid;
  border-radius: 2px;
  padding: 20px;
  transition: 0.2s ease-in;
}
.date-input:hover {
  border: 2px #b48d52 solid;
}

.date-input span {
  color: grey;
  font-size: 16px;
}

.date-range {
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.164);
}

.date-btn {
  border: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  padding: 10px;
  background-color: #b48d52;
  cursor: pointer;
  transition: 0.3s ease-in-out;
}
.date-btn:hover {
  background-color: #063b6d;
}

@media screen and (max-width: 1050px) {
  .booking {
    padding: 3rem;
  }

  .booking-form {
    padding: 2rem;
  }
}

@media screen and (max-width: 700px) {
  .booking {
    padding: 2rem 1rem;
  }

  .booking-form {
    padding: 2rem;
  }

  label {
    font-size: 15px;
  }

  .input-text {
    margin: 10px 0px 10px 0px;
    margin-bottom: 10px;
    padding: 15px;
  }

  .radio {
    margin-top: 10px;
  }
}
